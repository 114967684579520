import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { media } from '@dfds-ui/theme'

const PriceDisclaimer = () => {
  const { t } = useTranslation()

  return (
    <div
      css={css`
        margin: 0 20px 40px;

        ${media.greaterThan('l')`
          margin: 0 0 40px;
        `}
      `}
    >
      {t('FERRY-CROSSING-PRICE-DISCLAIMER')}
    </div>
  )
}

export default PriceDisclaimer
